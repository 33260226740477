body {
  background-color: #2f6387 ;
  margin: 0px;
  font-family: 'Roboto', sans-serif;
  background-image: url("../img/bg.png");
  background-repeat: repeat-x;
}

img {
  width: 100%;
  height: 100%;
}

h1 {
  font-size: 45px;
  margin-top: 0px;
}

h2 {
  margin-top: 0px;
}

hr {
  border-color: #d9d9d9;
  width: 75%;
  margin-top: 0px;
}

.text-img-combo {
  display: flex;
  align-items: center;
  opacity: .5;
}

.text-img-combo img {
  width: 70px;
  margin-right: 25px;
}

a {
  color: #1E88E5;
}

h5 {
  margin: 0px;
  opacity: .25;
}

/* //////////////////////////////////////////// */


.button:hover {
  scale: 1.5;
  opacity: .75;
  cursor: pointer;
  transform: scale(.95);
}

#content-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f5f5f5;
  margin: 0px;
  width: 100%;
  margin-top: 50px;
}

.content-bubble {
  display: flex;
  width: 50%;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 40px;
}

.content-bubble-image-wrapper {
  width: 30%;
  /* height: 50%; */
}

.content-text-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 25px;
  width: 70%;
}

@media only screen and (max-width: 1024px) {
  .content-bubble {
    width: 100%;
    flex-direction: column;
    align-items: center;
    margin-top: 0px;
  }

  .content-bubble-image-wrapper {
    width: 100%;
  }

  .content-text-wrapper {
    margin-top: 20px;
    width: 80%;
  }

  hr {
    display: none;
  }

  h1 {
    margin-top: 25px;
  }
}

#footer {
  display: flex;
  align-items: center;
  flex-direction: column;
  color: white;
  padding: 10px;
  background-color: #313639;
}

