#nav-container {
  margin: 0x;
}

#logo {
  width: 15%;
  margin: 25px;
}

@media only screen and (max-width: 1024px) {
  #logo {
    width: 45%;
    margin: 10px;
  }
}