#jumbotron-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  
  margin: 15px;
  margin-top: 0px;
  margin-bottom: 50px;
}

#player-wrapper {
  position: relative;
  padding-top: 56.25% /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

#header {
  width: 40%;
  margin-top: 25px;
}

#info-bar {
  width: 30%;
  color: white;
  background-color: #ff4d4d;
  padding-left: 20px;
  border-radius: 10px;
  margin-top: 50px;
  margin-bottom: 0px;
}

@media only screen and (max-width: 1024px) {
  #info-bar {
    width: 90%;
  }
  
  #header {
    width: 100%;
  }
}